const state = () => ({
  navigatorStatus: navigator.onLine,
  forcedOffline:
    JSON.parse(localStorage.getItem('akern_forced_offline')) || null,
  port: null
})

const getters = {
  onLine: (state, getters, rootState, rootGetters) => {
    if (rootGetters['config/type'] === 'shared') {
      return false
    }

    return state.forcedOffline ? false : state.navigatorStatus
  },
  forcedOffline: state => state.forcedOffline,
  port: state => state.port,
  localAPI: state => `http://localhost:${state.port}`
}

const mutations = {
  setStatus(state, status) {
    console.log('status updated: onLine = ' + status)
    state.navigatorStatus = status
    if (status) {
      state.forcedOffline = false
      localStorage.removeItem('akern_forced_offline')
    }
    this.$axios.defaults.baseURL = status
      ? process.env.baseUrl
      : `http://localhost:${state.port}`
    console.log('$axios baseUrl: ' + this.$axios.defaults.baseURL)
  },
  forceOffline(state, status) {
    console.log('forced offline = ' + status)
    state.forcedOffline = status
    localStorage.setItem('akern_forced_offline', status)
    this.$axios.defaults.baseURL = status
      ? `http://localhost:${state.port}`
      : process.env.baseUrl
    console.log('$axios baseUrl: ' + this.$axios.defaults.baseURL)
  },
  setPort(state, port) {
    state.port = port
  }
}

const actions = {
  updateStatus({ commit }, statusString) {
    if (statusString === 'backToOnLine') {
      console.log('welcome back to onLine!')
      // commit('setStatus', true)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
