<template>
  <div class="__nuxt-error-page">
    <network-status v-if="$platform === 'electron'" />
    <sync v-if="$platform === 'electron' && type === 'standalone'" />
    <div class="error">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="90"
        height="90"
        fill="#d8413e"
        viewBox="0 0 48 48"
      >
        <path
          d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z"
        />
      </svg>

      <div class="title">{{ message }}</div>
      <template v-if="$platform === 'electron' && message === 'Network Error'">
        <p class="description">
          {{ $t('app.offline.error') }}
          <b-button variant="success" class="mt-3" block @click="setOffline">
            {{ $t('app.offline.button.accessOffline') }}
          </b-button>
        </p>
      </template>
      <template v-else>
        <p v-if="statusCode === 404" class="description">
          <NuxtLink class="error-link" to="/">Back to the home page</NuxtLink>
        </p>

        <p v-else class="description">
          An error occurred while rendering the page
          <br />
          <a class="error-link" @click="$router.go(-1)">Go back</a>
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import NetworkStatus from '~/components/networkStatus'
import Sync from '~/components/sync'

export default {
  name: 'NuxtError',
  components: {
    NetworkStatus,
    Sync
  },
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head() {
    return {
      title: this.message,
      meta: [
        {
          name: 'viewport',
          content:
            'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      type: 'config/type'
    }),
    statusCode() {
      return (this.error && this.error.statusCode) || 500
    },
    message() {
      return this.error.message || `Error`
    }
  },
  methods: {
    ...mapMutations({
      forceOffline: 'network/forceOffline'
    }),
    setOffline() {
      this.forceOffline(true)
      this.$router.push(
        this.localePath({
          name: 'index'
        })
      )
    }
  }
}
</script>

<style lang="scss">
.__nuxt-error-page {
  padding: 1rem;
  background: #f5f5f5;
  color: #47494e;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
  font-weight: 100 !important;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .offline-message {
    font-size: 1.5em;
    max-width: 640px;
  }
  .error {
    max-width: 450px;
  }
  .title {
    font-size: 1.5rem;
    margin-top: 15px;
    color: #47494e;
    margin-bottom: 8px;
  }
  .description {
    color: #7f828b;
    line-height: 21px;
    margin-bottom: 10px;
  }
  a {
    color: #7f828b !important;
    text-decoration: none;
  }
}
</style>
